
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import moment from "moment";
import { FolderDataSource } from "@/data/Tree/FolderDataSource";
@Component
export default class Home extends Vue {
  moment: any = moment;
  dataSource: ListDataSource = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
    className: "feature",
  });

  folderDataSource: FolderDataSource = new FolderDataSource({
    className: "featureFolder",
    rootFolder: "mainFolder",
  });

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Особенности",
        disabled: true,
      },
    ];
  }
}
